
            @import 'src/styles/mixins.scss';
        
.text-block {
    @include handleTextBlockVariables();
}

.modal-trigger {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}